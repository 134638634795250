import * as S from "@effect/schema/Schema";

/**
 * This is the data model of the bookmarks and reading position used
 * by the native readers.
 */

export const MediaType = S.string.pipe(S.nonEmpty());
export const Href = S.string.pipe(S.nonEmpty());

/**
 * Locator for EPUB media type
 *
 * @property {string} type The RFC 6838 media type.
 * @property {string} locatorType For epubs, this must always be "epub".
 * @property {string} href Location reference in the publication
 * @property {number} chapterProgression Chapter progression as float in range [0, 1]
 * @property {number} totalProgression Contains an overall progression as float in range [0, 1] in the publication based on the reading order. Used for sorting and display and not for navigation purposes.
 * @property {string} title The title of the bookmark, which defaults to the title of the chapter
 */
export const LocatorEPUB = S.struct({
  type: MediaType,
  locatorType: S.literal("epub"),
  href: Href,
  chapterProgression: S.number,
  totalProgression: S.optional(S.number),
  title: S.optional(S.string),
});
export type LocatorEPUB = S.Schema.To<typeof LocatorEPUB>;

/**
 * Locator for PDF publications
 *
 * @property {string} type The RFC 6838 media type.
 * @property {string} title The title of the bookmark, which defaults to the title of the chapter
 * @property {string} locatorType For pdfs, this must always be "pdf".
 * @property {string} href Location reference in the publication
 * @property {number} page Non-negative integer page index
 */
export const LocatorPDF = S.struct({
  type: MediaType,
  locatorType: S.literal("pdf"),
  href: Href,
  page: S.number,
  title: S.optional(S.string),
});
export type LocatorPDF = S.Schema.To<typeof LocatorPDF>;

/**
 * Locator for audiobooks.
 *
 * @property {string} locatorType For audiobooks, this must always be "audio".
 * @property {string} title The title of the bookmark, which defaults to the title of the chapter
 * @property {number} [part] Non negative part index for Findaway audiobooks, 0 otherwise
 * @property {number} chapter Non negative chapter (or sequence) index
 * @property {number} chapterDuration Total duration of the chapter in milliseconds
 * @property {number} elapsedTime Current elapsed time in the chapter in millisconds
 */
export const LocatorAudiobook = S.struct({
  type: MediaType,
  locatorType: S.literal("audio"),
  part: S.number,
  chapter: S.number,
  chapterDuration: S.number,
  elapsedTime: S.number,
  title: S.optional(S.string),
});
export type LocatorAudiobook = S.Schema.To<typeof LocatorAudiobook>;

/**
 * Locator union type. This is used inside a bookmark, or to represent
 * a reading position. The `type` property is used to discriminate the union.
 */
export const Locator = S.union(LocatorEPUB, LocatorPDF, LocatorAudiobook);
export type Locator = S.Schema.To<typeof Locator>;

export const BookmarkStatus = S.literal("SUCCESS", "LOADING", "FAILURE");
export type BookmarkStatus = S.Schema.To<typeof BookmarkStatus>;

export const Bookmark = S.struct({
  id: S.string.pipe(S.nonEmpty()),
  status: BookmarkStatus,
  locator: Locator,
});
export type Bookmark = S.Schema.To<typeof Bookmark>;

/**
 * NewPartialBookmark is the type sent by the Reader when creating a bookmark.
 * It doesn't yet have an `id` or `status` since those are set by the server
 * and/or the web.
 */
export const NewBookmark = Bookmark.pipe(S.omit("id", "status"));
export type NewBookmark = S.Schema.To<typeof NewBookmark>;

export type CREATE_BOOKMARK_EVENT = "CREATE_BOOKMARK";
export const CreateBookmarkAction = S.struct({
  bookmark: NewBookmark,
});
export type CreateBookmarkAction = S.Schema.To<typeof CreateBookmarkAction>;

export type DELETE_BOOKMARK_EVENT = "DELETE_BOOKMARK";
export const DeleteBookmarkAction = S.struct({
  id: S.string.pipe(S.nonEmpty()),
});
export type DeleteBookmarkAction = S.Schema.To<typeof DeleteBookmarkAction>;

export type UPDATE_POSITION_EVENT = "UPDATE_POSITION";
export const UpdatePositionAction = S.struct({
  position: Locator,
});
export type UpdatePositionAction = S.Schema.To<typeof UpdatePositionAction>;

export type BOOK_CLOSED_EVENT = "BOOK_CLOSED";

export type ReaderPluginEvent =
  | CREATE_BOOKMARK_EVENT
  | DELETE_BOOKMARK_EVENT
  | UPDATE_POSITION_EVENT
  | BOOK_CLOSED_EVENT;
