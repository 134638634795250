import * as S from "@effect/schema/Schema";
import * as ParseResult from "@effect/schema/ParseResult";
import * as E from "effect/Either";
import { ParseError } from "../errors";
import {
  Bookmark as ClientBookmark,
  Locator as ClientLocator,
  Href,
  MediaType,
  NewBookmark as NewClientBookmark,
} from "shared/reader-plugin/schema";

/**
 * We need to validate unknown JSON into ServerBookmark and ServerLocator, and then
 * transform it to a ClientBookmark and ClientLocator. We need to do the opposite before sending
 * new bookmarks and reading locations to the server.
 *
 * This happens in three places:
 *   1. When fetching /annotations (AnnotationCollection). The AnnotationsCollection is
 *      JSON, and it also contains nested stringified JSON in `selector.target.value`,
 *      which could be anything we sent to it.
 *   2. When receiving JSON from the Readers in the `createBookmark` event, before sending
 *      to server.
 *   3. When receiving a reading position in the `updateReadingPosition` event, before
 *      sending to server.
 *
 * There are three sets of schemas:
 *  (1) the Locator, Bookmark, NewBookmark. These are the types expected by the Reader plugin.
 *      We import them as ClientLocator, ClientBookmark, NewClientBookmark to be specific, since
 *      they are different from the server versions.
 *  (2) the ServerLocator, ServerBookmark. These are stringified JSON values the server stores
 *      in the `target.selector.value` of an annotation. They have a slightly different shape. The
 *      title is not located in the locator, but in the bookmark, the id is stored in the annotation id,
 *      the status is removed (it is always SUCCESS on the server), the locatorType is renamed to type,
 *      and the type is renamed to mediaType.
 *  (3) the BookmarkAnnotation, PositionAnnotation. These are the full "annotations" the server works with.
 *      They contain ServerLocators and ServerBookmarks nested within.
 *
 * The schemas we need to validate these three places:
 *   1. Locator - the locator object used by the reader(in reader plugin)
 *   2. Bookmark - a bookmark with `id` and `status`. (in reader plugin)
 *   3. NewBookmark - a bookmark without `id` and `status`, sent
 *      in `createBookmark` event. (in reader plugin)
 *   4. ServerBookmark - a stringified bookmark without `id` and `status`
 *   5. ServerReadingPosition - a stringified locator
 *   6. BookmarkAnnotation - a full Annotation with a ServerBookmark nested within.
 *   7. NewBookmarkAnnotation - what we send to create a new bookmark.
 *   7. PositionAnnotation - full Annotation with a ServerLocator nested within.
 *   8. AnnotationCollection - a collection of Annotations
 *
 * This file defines the schemas for the Circulation Manager Annotation data model.
 * The schema for the ReaderLocator and ReaderBookmark are defined in the reader
 * plugin definition.
 *
 * The CM annotations endpoint is built around the W3C Web Annotation Data Model
 * (https://www.w3.org/TR/annotation-model), but we aren't currently using it
 * properly. The endpoint should store a Locator in `target.selector`, but
 * it only accepts a string currently. For that reason, we stringify our Bookmark
 * or reading position Locator and store it in `target.selector.value` of the annotation.
 *
 * This stringified JSON is not validated by the server and is the root of the "danger" of
 * this system. Anything that makes its way into `target.selector.value` will be regurgitated
 * by the server, and if a bad value gets in there it could break the readers. This is
 * why we have to strongly validate this data before sending it and when receiving it. We treat
 * the annotations endpoint as an untrusted source.
 */

/**
 * Wraps the parse function to wrap the error in our own ParseError.
 */
const parseOrError =
  <From, To = From>(schema: S.Schema<From, To>) =>
  (input: unknown) => {
    const result = S.parseEither(schema)(input);
    if (E.isLeft(result)) {
      throw new ParseError(result.left);
    }
    return result.right;
  };

export const ReadingPositionMotivation =
  "http://librarysimplified.org/terms/annotation/idling";
export const BookmarkMotivation = "http://www.w3.org/ns/oa#bookmarking";

/**
 * Build a ServerLocator schema. It is almost the same as a ClientLocator, except:
 *  - ClientLocator.Type -> ServerLocator.mediaType
 *  - ClientLocator.locatorType -> ServerLocator.type
 *  - ClientLocator.title is removed (it will be stored in the bookmark)
 */
const ServerLocatorEPUB = S.struct({
  mediaType: MediaType,
  type: S.literal("epub"),
  href: Href,
  chapterProgression: S.number,
  totalProgression: S.optional(S.number),
});
export const ServerLocatorPDF = S.struct({
  type: S.literal("pdf"),
  mediaType: MediaType,
  href: Href,
  page: S.number,
});
export const ServerLocatorAudiobook = S.struct({
  type: S.literal("audio"),
  mediaType: MediaType,
  part: S.number,
  chapter: S.number,
  chapterDuration: S.number,
  elapsedTime: S.number,
});
// ServerLocator is a Schema From a string To a ServerLocator object
export const ServerLocator = S.union(
  ServerLocatorEPUB,
  ServerLocatorPDF,
  ServerLocatorAudiobook,
);
export type ServerLocator = S.Schema.To<typeof ServerLocator>;

export const ServerLocatorStringified = S.ParseJson.pipe(
  S.compose(ServerLocator),
);
export type ServerLocatorStringified = S.Schema.To<
  typeof ServerLocatorStringified
>;

/**
 * The OE server version of a Bookmark
 *  - has a ServerLocator instead of a ClientLocator
 *  - doesn't have an id. The id is stored in the annotation id.
 *  - doesn't have a status. The status is always SUCCESS if it is on the server.
 *  - The title is not located in the locator, but in the bookmark. (as opposed to on PJR)
 */
export const ServerBookmark = S.ParseJson.pipe(
  S.compose(
    S.struct({
      title: S.string,
      locator: ServerLocator,
    }),
  ),
);
export type ServerBookmark = S.Schema.To<typeof ServerBookmark>;

/**
 * BookmarkAnnotation is an annotation with a BookmarkMotivation and
 * a ServerBookmark nested within.
 */
export const BookmarkAnnotation = S.struct({
  motivation: S.literal(BookmarkMotivation),
  id: S.string,
  target: S.struct({
    selector: S.struct({
      // the value is the stringified JSON of the reader bookmark.
      value: ServerBookmark,
    }),
    source: S.string.pipe(S.nonEmpty()),
  }),
});
export type BookmarkAnnotation = S.Schema.To<typeof BookmarkAnnotation>;

/**
 * PositionAnnotation is an annotation with a ReadingPositionMotivation and
 * a ServerLocator nested within.
 */
export const PositionAnnotation = S.struct({
  motivation: S.literal(ReadingPositionMotivation),
  id: S.string,
  target: S.struct({
    selector: S.struct({
      value: ServerLocatorStringified,
    }),
    source: S.string,
  }),
});
export type PositionAnnotation = S.Schema.To<typeof PositionAnnotation>;

const Annotation = S.union(BookmarkAnnotation, PositionAnnotation);

type Annotation = S.Schema.To<typeof Annotation>;

/**
 * AnnotationCollection is a collection of either ReadingPositionAnnotations
 * or BookmarkAnnotations. It is returned by the server in a GET request to
 * the annotations endpoint. We will leave the `items` as unknown initially
 * since we need to parse and filter them individually not as a whole.
 */
export const AnnotationCollection = S.struct({
  total: S.number,
  first: S.struct({
    // we leave this unknown initially, since we will parse them individually
    items: S.array(S.unknown),
  }),
});
export type AnnotationCollection = S.Schema.To<typeof AnnotationCollection>;

export type InvalidRecord = {
  errors: ParseResult.ParseError;
  annotation: unknown;
};

export type ParseAnnotationCollectionResult = {
  readingPosition?: ClientLocator;
  bookmarks: ClientBookmark[];
  invalid: InvalidRecord[];
};

/**
 * Parses an AnnotationCollection into readingPosition, clientBookmarks and invalids.
 *   - filters out any invalid annotations and returns them as `invalid` with error messages.
 *   - returns the reading position as a Locator
 *   - returns the bookmarks as an array of Bookmarks
 */
export function parseAnnotationCollection(json: unknown) {
  // first parse the annotation collection wrapper
  const collection = parseOrError(AnnotationCollection)(json);

  // then parse each annotation individually, filtering out invalids
  const invalid: InvalidRecord[] = [];
  let readingPosition: ClientLocator | undefined;
  const clientBookmarks: ClientBookmark[] = [];
  for (const item of collection.first.items) {
    const result = S.parseEither(Annotation)(item, {
      errors: "all",
    });

    if (E.isLeft(result)) {
      invalid.push({ errors: result.left, annotation: item });
    } else {
      // if this is a reading position, save it to the reading positions
      if (result.right.motivation === ReadingPositionMotivation) {
        // if there is already a reading position parsed, the second one is
        // an error
        if (readingPosition) {
          const error = ParseResult.parseError([
            ParseResult.type(
              BookmarkAnnotation.ast,
              item,
              "Extra position annotation. There can only be one position annotation per book.",
            ),
          ]);
          invalid.push({ errors: error, annotation: item });
        } else {
          readingPosition = readingPositionFromAnnotation(result.right);
        }
      } else {
        clientBookmarks.push(bookmarkFromAnnotation(result.right));
      }
    }
  }

  return {
    readingPosition,
    bookmarks: clientBookmarks,
    invalid,
  };
}

/**
 * Takes a BookmarkAnnotation from the server containing a ServerBookmark and
 * ServerLocator and converts it into a ClientBookmark with ClientLocator
 */
export function bookmarkFromAnnotation(annotation: BookmarkAnnotation) {
  const { locator, title, ...rest } = annotation.target.selector.value;

  return {
    ...rest,
    // use the id of the annotation as the id of the bookmark.
    id: annotation.id,
    // add a constant status of "SUCCESS"
    status: "SUCCESS" as const,
    locator: serverLocatorToClientLocator(locator, title),
  } as const;
}

function readingPositionFromAnnotation(
  annotation: PositionAnnotation,
): ClientLocator {
  return serverLocatorToClientLocator(annotation.target.selector.value);
}

/**
 * Convert ServerLocator to ClientLocator by reassigning `type` to `locatorType`.
 * During this process, mapping from one discriminated union to another, Typescript loses
 * it's ability to know that what you're doing is safe, because it sees the ServerLocator.type
 * as "epub" | "pdf" | "audiobook", and it thinks you could be constructing eg. a LocatorPDF but
 * giving it a `locatorType` that is "audiobook" or "ebook". We could solve this using complex if/then
 * statements to convince the compiler it's safe, but it is easier to use a type assertion and we know
 * it is safe.
 */
export function serverLocatorToClientLocator(
  locator: ServerLocator,
  title?: string,
): ClientLocator {
  const { type, mediaType, ...locatorRest } = locator;
  const clientLocator = {
    type: mediaType,
    locatorType: type,
    title,
    ...locatorRest,
  } as ClientLocator;
  return clientLocator;
}
export function clientLocatorToServerLocator(
  locator: ClientLocator,
): ServerLocator {
  const { type, locatorType, title: _title, ...locatorRest } = locator;
  const serverLocator = {
    type: locatorType,
    mediaType: type,
    ...locatorRest,
  } as ServerLocator;
  return serverLocator;
}

/**
 * The annotation data we send to the server to update the current position.
 * Sending undefined is the same as deleting, it seems.
 */
export const NewPositionAnnotation = S.struct({
  "@context": S.literal("http://www.w3.org/ns/anno.jsonld"),
  motivation: S.literal(ReadingPositionMotivation),
  target: S.struct({
    selector: S.struct({
      value: ServerLocatorStringified,
    }),
    source: S.string,
  }),
});

export type NewPositionAnnotation = S.Schema.To<typeof NewPositionAnnotation>;
// the encoded version is the type that is sent to the server with the stringified
// target.selector.value
export type EncodedNewPositionAnnotation = S.Schema.From<
  typeof NewPositionAnnotation
>;
/**
 * Converts a ClientLocator to a NewServerPositionAnnotation ready to be sent to
 * the server.
 */
export function createNewServerPositionAnnotation(
  clientLocatorJson: unknown,
  bookId: string,
): EncodedNewPositionAnnotation {
  // parse it to make sure it is a valid client locator
  const clientLocator = parseOrError(ClientLocator)(clientLocatorJson);
  // transform it to a serverLocator
  const serverLocator = clientLocatorToServerLocator(clientLocator);
  // encode it into an EncodedNewPositionAnnotation
  const encodedPositionAnnotation = S.encodeSync(NewPositionAnnotation)({
    "@context": "http://www.w3.org/ns/anno.jsonld",
    motivation: ReadingPositionMotivation,
    target: {
      selector: {
        value: serverLocator,
      },
      source: bookId,
    },
  });
  return encodedPositionAnnotation;
}

export const NewBookmarkAnnotation = S.struct({
  motivation: S.literal(BookmarkMotivation),
  "@context": S.literal("http://www.w3.org/ns/anno.jsonld"),
  target: S.struct({
    selector: S.struct({
      value: ServerBookmark,
    }),
    source: S.string,
  }),
});
export type NewBookmarkAnnotation = S.Schema.To<typeof NewBookmarkAnnotation>;
// the encoded version has a stringified `target.selector.value`
export type EncodedNewBookmarkAnnotation = S.Schema.From<
  typeof NewBookmarkAnnotation
>;

/**
 * This function accepts unknown JSON from the reader plugin. It
 *  1. Validates the JSON against the NewClientBookmark schema
 *  2. Transforms it into a NewServerBookmark
 *  3. Encodes it into an EncodedNewBookmarkAnnotation
 * Returns all three
 */
export function createNewServerBookmarkAnnotation(
  newClientBookmarkJson: unknown,
  source: string,
) {
  // validate that it is a valid new bookmark.
  const newClientBookmark = parseOrError(NewClientBookmark)(
    newClientBookmarkJson,
  );
  // transform it to a serverBookmark
  const newServerBookmark: ServerBookmark = {
    ...newClientBookmark,
    // TODO: What to do if the locator.title is undefined?
    title: newClientBookmark.locator.title ?? "Untitled",
    locator: clientLocatorToServerLocator(newClientBookmark.locator),
  };
  // encode it into an EncodedNewPositionAnnotation
  const encodedNewServerBookmarkAnnotation = S.encodeSync(
    NewBookmarkAnnotation,
  )({
    motivation: BookmarkMotivation,
    "@context": "http://www.w3.org/ns/anno.jsonld",
    target: {
      selector: {
        value: newServerBookmark,
      },
      source,
    },
  });

  return {
    encodedNewServerBookmarkAnnotation,
    newClientBookmark,
  };
}

export const parseBookmarkAnnotation = parseOrError(BookmarkAnnotation);
